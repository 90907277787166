.landing-nav-label-text{
    /* text-shadow: 0.1rem 0.1rem 0.1rem black; */
    padding: 0 0 0.5rem 0;
    color: black;
}

.landing-nav-input-container{
  text-shadow: 0.1rem 0.1rem 0.1rem black;
  padding: 0 0 0 0;
  color: white;
  width: 98%;
  border-radius: 0.5rem;
  display: flex;
}

.landing-nav-input{
flex-grow: 2;
/* width: 100%; */
height: 1.5rem;
border-radius: 0.5rem;
padding-left: 0.5rem;
}

.landing-nav-button{
  color: rgb(50,50,50);
  background-color: cornsilk;
  cursor: pointer;
  border-radius: 1rem;
  display: inline-block;
  padding: 0rem 1rem;
  border: none;
  margin-left: 0.25rem;
  display: flex;
  align-items: center;
}

.landing-panel-container{
  display: flex;
  flex-flow: column;
  padding-top: 1rem;
}

.clickable-list-item{
  background-color: white;
  padding: 0.75rem;
  cursor: pointer;
  border: 1px solid black;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bracket-id-list{
  padding: 0rem 0;
}
.bracket-id-list-container{
  padding: 0rem 0;
  overflow-y: auto;
  max-height: 40vh;
}

.clickable-list-item:first-child {
  border-radius: 1rem 1rem 0 0;
}

.clickable-list-item:last-child {
  border-radius: 0rem 0rem 1rem 1rem;
}

.clickable-list-item:only-child {
  border-radius: 1rem 1rem 1rem 1rem;
}

/* .bracket-id-list-header{
  font-size: 1.2rem;
  text-shadow: 0.1rem 0.1rem 0.1rem black;
  color: white;

} */

.clickable-list-item:hover{
  background-color: azure;
}

.new-bracket-button{
  color: #fcfcfc;
  background-color: #3f915f;
  cursor: pointer;
  border-radius: 1rem;
  display: inline-block;
  padding: 0.5rem 1.5rem;
  border: none;
  margin: 0rem 3rem 0;
  box-shadow: 0.1px 1.5px 2px black;
}

.new-bracket-button:hover{
  background-color: #5f8e72;
}


.tourney-id-input{
  width: 100%;
  height: 1.5rem;
  border-radius: 0.5rem;
  padding-left: 0.5rem;
}
.tourney-id-input-container{
  text-shadow: 0.1rem 0.1rem 0.1rem black;
  padding: 0 0 0.5rem 0;
  color: white;
  width: 98%;
  border-radius: 0.5rem;
  display: flex;
}

.landing-bracket-divider{
  margin: 1rem 0 1rem 0;
}