.publish-button-container{
    
  position: fixed;
  z-index: 1;
  top: 0rem;
  right: 5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
}

.publish-button{
    height: 4rem;
    width: 4rem;
    border-radius: 2rem;
    background-color: rgb(122 27 27);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: .1rem 0.1rem 0.5rem rgb(0 0 0 / 50%);
}
.publish-button:hover{
    background-color: rgb(122 61 61);
}
  
  .publish-icon{
    max-height: 2.5rem;
    padding: 0 1rem;
  }