@font-face {
  font-family: 'AmericanCaptain';
  src: local('AmericanCaptain'), url(./fonts/American-Captain.ttf) format('truetype');
}
@font-face {
  font-family: 'LouisGeorge';
  src: local('LouisGeorge'), url(./fonts/Louis-George-Cafe.ttf) format('truetype');
}
@font-face {
  font-family: 'Retroica';
  src: local('Retroica'), url(./fonts/Retroica.ttf) format('truetype');
}

body {
  font-family: 'Istok Web', sans-serif;
  /* background: url("http://picjumbo.com/wp-content/uploads/HNCK2189-1300x866.jpg") no-repeat #000; */
  background-color: rgba(225,225,225,0.9);
  background-size: cover;
  min-height: 100%;
  margin: 0;
}
.hero {
  position:relative;
   text-align: center;
   overflow: hidden;
   color: #fcfcfc;
}
.hero h1 {
  font-family: 'Holtwood One SC', serif;
  font-weight: normal;
  font-size: 5.4em;
  margin:0 0 20px;
   text-shadow:0 0 12px rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  letter-spacing:-1px;
}
.hero p {
  font-family: 'Abel', sans-serif;
  text-transform: uppercase;
   color: #5CCA87;
   letter-spacing: 6px;
  text-shadow:0 0 12px rgba(0, 0, 0, 0.5);
  font-size: 1.2em;
}
.hero-wrap {
  padding: 3.5em 10px;
}
.hero p.intro {
  font-family: 'Holtwood One SC', serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 3em;
  margin-bottom:-40px;
}
.hero p.year {
  color: #fff;
   letter-spacing: 20px;
   font-size: 34px;
   margin: -25px 0 25px;
}
.hero p.year i {
  font-size: 14px;
  vertical-align: middle;
}
#bracket {
  overflow:hidden;
  background-color: #e1e1e1;
  background-color:rgba(225,225,225,0.9);
  padding-top: 20px;
  font-size: 12px;
  padding: 40px 0;
}

.main-bracket {
  padding: 3rem 0 2rem;
}

.bracket-header-container{
  display: flex;
  justify-content: center;
  padding-bottom: 1.5rem;
}

.bracket-header{
  max-width: 70%;
  text-align: center;
  font-size: 2.5rem;
  letter-spacing: 1px;
  font-family: "LouisGeorge";
  color: #3F915F;
  text-shadow: .05rem 0.05rem 0.15rem rgb(0 0 0 / 10%);
}
.container {
  max-width: 1100px;
  margin: 0 auto;
  display:block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction:column;
  flex-direction: column;
  overflow-x: auto;
  border-top: 1px solid white;
    border-bottom: 1px solid white;
    padding: 0rem 1rem;
}
.split {
  display:block;
  float:left;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display:flex;
  width: 100%;
  -webkit-flex-direction:row;
  -moz-flex-direction:row;
  flex-direction:row;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.champion {
  float:left;
  display:block;
  width: 16%;
  -webkit-flex-direction:row;
  flex-direction:row;
  -webkit-align-self:center;
  align-self:center;
  margin-top: -15px;
  text-align: center;
  padding: 230px 0\9;
}
.champion i {
  color: #a0a6a8;
   font-size: 45px;
  padding: 10px 0;
}
.round {
  display:block;
  float:left;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display:flex;
  -webkit-flex-direction:column;
  flex-direction:column;
  width:95%;
  width:30.8333%\9;
  border-right: 1px solid #dbdbdb;
  padding-right: 1.5%;
  min-width: 7rem;
}

.split-one .round {
  margin: 0 1.5% 0 0;
}
.split-two .round {
  margin: 0 0 0 2.5%;
}
.matchup {
  margin:0;
  width: 100%;
  padding: 10px 0;
  /* height:60px; */
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  /* display: flex;
  align-items: center; */
}
.score {
  font-size: 11px;
  text-transform: uppercase;
  float: right;
  color: #2C7399;
  font-weight: bold;
  font-family: 'Roboto Condensed', sans-serif;
  position: absolute;
  right: 5px;
}

.round-details {
  font-family: 'Roboto Condensed', sans-serif;
   font-size: 13px;
   color: #2C7399;
  text-transform: uppercase;
  text-align: center;
  height: 40px;
}
.champion li, .round li {
  background-color: #fff;
  box-shadow: none;
   opacity: 0.45;
}
.current li {
  opacity: 1;
}
.current li.team {
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  opacity: 1;
}
.vote-options {
  display: block;
  height: 52px;
}
.share .container {
  margin: 0 auto;
   text-align: center;
}
.share-icon {
  font-size: 24px;
   color: #fff;
  padding: 25px;
}
.share-wrap {
  max-width: 1100px;
   text-align: center;
   margin: 60px auto;
}
.final {
  margin: 4.5em 0;
}
@-webkit-keyframes pulse {
   0% {
       -webkit-transform: scale(1);
       transform: scale(1);
  }
   50% {
       -webkit-transform: scale(1.3);
       transform: scale(1.3);
  }
   100% {
       -webkit-transform: scale(1);
       transform: scale(1);
  }
}
@keyframes pulse {
   0% {
       -webkit-transform: scale(1);
       -ms-transform: scale(1);
       transform: scale(1);
  }
   50% {
       -webkit-transform: scale(1.3);
       -ms-transform: scale(1.3);
       transform: scale(1.3);
  }
   100% {
       -webkit-transform: scale(1);
       -ms-transform: scale(1);
       transform: scale(1);
  }
}
.share-icon {
  color: #fff;
   opacity: 0.35;
}
.share-icon:hover {
   opacity:1;
   -webkit-animation: pulse 0.5s;
   animation: pulse 0.5s;
}
.date {
  font-size: 10px;
   letter-spacing: 2px;
  font-family: 'Istok Web', sans-serif;
  color:#3F915F;
}
@media screen and (min-width: 981px) and (max-width: 1099px) {
   .container {
      margin: 0 1%;
  }
   .champion {
      width: 14%;
  }
   .split {
      width:100%;
  }
   .split-one .vote-box {
      margin-left: 138px;
  }
   .hero p.intro {
      font-size: 28px;
  }
   .hero p.year {
      margin: 5px 0 10px;
  }
}
@media screen and (max-width: 980px) {
   .container {
      -webkit-flex-direction:column;
      -moz-flex-direction:column;
      flex-direction:column;
  }
   .split, .champion {
      width: 90%;
      margin: 35px 5%;
  }
   .champion {
      -webkit-box-ordinal-group:3;
      -moz-box-ordinal-group:3;
      -ms-flex-order:3;
      -webkit-order:3;
      order:3;
  }
   .split {
       padding-bottom: 20px;
  }
   .hero p.intro {
      font-size: 24px;
  }
   .hero h1 {
      font-size: 3em;
       margin: 15px 0;
  }
   .hero p {
      font-size: 1em;
  }
}
@media screen and (max-width: 400px) {
   .split {
      width: 95%;
      margin: 25px 2.5%;
  }
   .round {
      width:21%;
  }
   .current {
      -webkit-flex-grow:1;
      -moz-flex-grow:1;
      flex-grow:1;
  }
   .hero h1 {
      font-size: 2.15em;
       letter-spacing: 0;
      margin:0;
  }
   .hero p.intro {
      font-size: 1.15em;
      margin-bottom: -10px;
  }
   .round-details {
      font-size: 90%;
  }
   .hero-wrap {
      padding: 2.5em;
  }
   .hero p.year {
      margin: 5px 0 10px;
       font-size: 18px;
  }
}


.control-icon{
    max-height: 1rem;
    max-width: 1rem;
    padding: 0.5rem;
    /* border-left: 1px solid white; */
    /* border-right: 1px solid white; */
    /* border-top: 1px solid white;*/   
}


.control-container{
  max-height: 2rem;
  border-radius: 0 0 0.25rem 0.25rem;
  box-shadow: 0.1rem 0.1rem 0.5rem rgb(0 0 0 / 30%);
  cursor: pointer;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.control-container.reset-icon{
  background-color: #3f9270;
}

.control-container.reset-icon:hover{
  background-color: #64937f;
}

.control-container.round-icon{
  background-color: #868686;
  color: white;
  height: 2rem;
  width: 2rem;
}

.control-container.round-icon:hover{
  background-color: #7e7e7e;
}
.control-container.round-icon.selected{
  background-color: #393939;
}

.bracket-controls{
  display: flex;
  justify-content: end;
  position: sticky;
  left: 0;
  padding: 0 1rem;
}

.breadcrumb-container{
  display: flex;
  justify-content: center;
}

.view-only-breadcrumb{
  background-color: #393939;
  color: white;
  padding: 0.5rem;
  border-radius: 0 0 0.5rem 0.5rem;
  font-size: 0.8rem;    
}

.view-only-brackets .main-bracket{
  padding: 0 0 1rem 0;
}

.tail-container{
  width: 2rem;
  background-color: #393939;
}

.left-tail-container{
  background-color: #e1e1e1;
  height: 100%;
  width: 100%;
  border-radius: 0 2rem 0 0;
}

.right-tail-container{
  background-color: #e1e1e1;
  height: 100%;
  width: 100%;
  border-radius: 2rem 0 0 0;
}