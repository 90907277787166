.loading-wall{
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
    background-color: rgba(0,0,0,0.7);
    -webkit-transition: all .12s ease;
    -moz-transition: all .12s ease;
    transition: all .12s ease;
}

.loading-wall.hide{
    height: 0%;
    width: 0%;
    top: 50%;
    left: 50%;
    /* display: none; */
}

.hide .loading-icon{
     display: none;
}