.landing-nav-container{
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  font-size: 2rem;
}

.landing-page-masthead{
  font-family: 'LouisGeorge';
  padding-bottom: 0.5rem;
}

.landing-nav-buttons{
  display: flex;
}

.nav-button{
  font-size: 1rem;
  padding: 0.5rem 0.75rem;
  border-radius: 1rem 1rem 1rem 1rem;
  cursor: pointer;
}

.nav-button:hover{
  background-color: aliceblue;
}

.nav-button-container{
  padding-right: 0.5rem;
}

.nav-button-container:last-child{
  padding-right: 0;
}

.bracket-logo{
  max-height: 6rem;
}