.home-button-container{
    
  position: fixed;
  z-index: 1;
  top: 0rem;
  left: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0.5rem;
}

.home-button{
    height: 4rem;
    width: 4rem;
    border-radius: 2rem;
    background-color: #4a4d4e;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: .1rem 0.1rem 0.5rem rgb(0 0 0 / 50%);
}
.home-button:hover{
    background-color: rgb(122 61 61);
}
  
  .home-icon{
    max-height: 2.5rem;
    padding: 0 1rem;
  }