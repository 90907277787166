.matchup-container{
    display: flex;
}   

.round-id{
    align-self: center;
}

.team {
    padding: 0 5px;
    margin: 3px 0;
    height: 25px;
     line-height: 25px;
    white-space: nowrap;
     overflow: hidden;
    position: relative;
  }
  .team.hoverable:hover {
    height: 30px;   
    line-height: 30px;
    margin-top: -2.5px;
    margin-left: -2.5px;
    margin-right: -2.5px;
    padding: 0 7.5px;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
  
  .current li.team.bye{
    background-color: #a0a6a8;
  }
  
  .current li.team.loser{
    background-color: #4a4d4e;
    color: rgba(200,200,200,1);
  }
  
  .current li.team.winner{
    /* background-color: #b4ffb4; */
    background-color: #3f9270;
    color: white;
  }
  
  .current li.team.to-be-played{
    background-color: #a0a6a8;
  }
  .current li.team.placeholder{
    font-style: italic;
    font-size: 0.6rem;
  }