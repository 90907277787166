

.panel-header{
    background-color: #e3e3e3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: -1rem -1rem 0 -1rem;
    height: 2rem;
    border-radius: 1rem 1rem 0 0;
    font-size: 1.5rem;
    padding: 1rem;
    border-bottom: 1px solid black;
}

.page-buttons{
    display: flex;
    justify-content: center;
}

.panel-background{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top:0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.7);
    -webkit-transition: all .12s ease;
    -moz-transition: all .12s ease;
    transition: all .12s ease;
}
.panel-background.hide{
    height: 0%;
    width: 0%;
    top: 50%;
    left: 50%;
}
.panel{
    border-radius: 1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    max-width: 80vw;
    max-height: 80vh;
    background-color: #e3e3e3;
}

.header-text{
    color: black;
    font-family: "LouisGeorge";
}

.standout-button{
	color: rgb(50,50,50);
	background-color: cornsilk;
	cursor: pointer;
	border-radius: 1rem;
	display: inline-block;
	padding: 0.5rem 1.5rem;
	border:none;
    margin: 0.5rem 0.5rem 0 0;

}
.input-container{
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    color: cornsilk;
}

.panel-button-container{
    display: flex;
    justify-content: center;
    background-color: rgb(25,25,25);
    margin: 0 -1rem -1rem -1rem;
    padding: 0.5rem 0 1rem 0;
    border-top: 3px solid cornsilk;
    border-radius: 0 0 1rem 1rem;
}


.cancel-button{
	color: white;
	background-color: rgb(50,50,50);
	cursor: pointer;
	border-radius: 1rem;
	display: inline-block;
	padding: 0.5rem 1.5rem;
	border:none;
    margin: 0.5rem 0 0 0;
}

.close-button{
    max-height: 1.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
}
