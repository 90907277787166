.side-panel{

  position: fixed;
  z-index: 1;
  right: 0.5rem;
  top: 0.5rem;
}

.side-panel-contents{
  background-color: #253e4c;
  padding: 5rem 1.8rem 2.5rem;
  border-radius: 2.5rem;
  box-shadow: 1px 1px 8px black;
}

@media only screen and (max-width: 600px) {
	.side-panel{
		max-width: 95%;
	}
}


.checkbox-container{
  padding-top: 0.5rem;
  display: flex;
  flex-flow: row-reverse;
  justify-content: start;
  font-size: 1rem;
  color: white;
  text-shadow: 0.1rem 0.1rem 0.1rem black;
  font-family: system-ui;
  line-height: 1rem;
}

.contestant-input-container{
  text-shadow: 0.1rem 0.1rem 0.1rem black;
  padding-top: 0.75rem;
  color: white;
}

.contestant-input{
  width: 98%;
  border-radius: 0.5rem;    
  padding-left: 0.25rem;
}
.tourney-name-input{
  width: 100%;
  height: 1.5rem;
  border-radius: 0.5rem;
  padding-left: 0.5rem;
}
.tourney-name-input-container{
  text-shadow: 0.1rem 0.1rem 0.1rem black;
  padding: 0 0 0.5rem 0;
  color: white;
  width: 98%;
  border-radius: 0.5rem;
}

.standout-button{
  color: #fcfcfc;
  background-color: #3f915f;
  cursor: pointer;
  border-radius: 1rem;
  display: inline-block;
  padding: 0.5rem 1.5rem;
  border: none;
  margin: 0.5rem 0.5rem 0 0;
  box-shadow: 0.1px 1.5px 5px black;
}