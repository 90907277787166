.styled-input{
	border: none;
	border-radius: 1rem;
	padding: 0.5rem 1rem;
}
.styled-input:focus{
	outline: none;
}

.contestant-box{
	border: none;
	border-radius: 1rem;
	padding: 0.5rem 1rem;
}

.pokemon-search-box{
	border: none;
	padding: 0.5rem 1rem;    
	color: white;
    background-color: rgba(105,0,220, 0.4);
}

.disable-input{
	pointer-events: none;
}