.publish-panel{

  position: fixed;
  z-index: 1;
  right: 0.5rem;
  top: 0.5rem;
}

.publish-panel-contents{
  background-color: rgb(122 27 27);
  padding: 5rem 1.8rem 1rem;
  border-radius: 2.5rem;
  box-shadow: 1px 1px 8px black;
}

@media only screen and (max-width: 600px) {
	.publish-panel{
		max-width: 95%;
	}
}


.checkbox-container{
  padding-top: 0.5rem;
  display: flex;
  flex-flow: row-reverse;
  justify-content: start;
  font-size: 1rem;
  color: white;
  text-shadow: 0.1rem 0.1rem 0.1rem black;
  font-family: system-ui;
  line-height: 1rem;
}

.contestant-input-container{
  text-shadow: 0.1rem 0.1rem 0.1rem black;
  padding-top: 0.75rem;
  color: white;
}

.contestant-input{
  width: 98%;
  border-radius: 0.5rem;    
  padding-left: 0.25rem;
}
.tourney-name-input{
  width: 100%;
  height: 1.5rem;
  border-radius: 0.5rem;
  padding-left: 0.5rem;
}
.tourney-name-input-container{
  text-shadow: 0.1rem 0.1rem 0.1rem black;
  padding: 0 0 0.5rem 0;
  color: white;
  width: 98%;
  border-radius: 0.5rem;
}

.publish-bracket-button{
  color: #fcfcfc;
  background-color: #3f915f;
  cursor: pointer;
  border-radius: 1rem;
  display: inline-block;
  padding: 0.5rem 1.5rem;
  border: none;
  margin: 1rem 0rem 0 0;
  box-shadow: 0.1px 1.5px 5px black;
}



.poll-detail-display{
  margin-top: 2rem;
  align-self: center;
}

.poll-detail-header{
  font-size: 1.2rem;
}

.poll-detail-subtext{
  font-size: 0.8rem;
}


.poll-link-display{
  border-radius: 2rem;
  color: white;
    text-shadow: 0.1rem 0.1rem 0.1rem rgb(0 0 0 / 50%);
}

.poll-link-form{
  padding-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: left;
}

.poll-link{
  flex-grow: 2;
}

.poll-link-button{
  color: rgb(50,50,50);
  background-color: cornsilk;
  cursor: pointer;
  border-radius: 1rem;
  display: inline-block;
  padding: 0.5rem 1rem;
  border: none;
  margin-left: 0.25rem;
}

.open-new-tab-icon{
  height: 1rem;
}

.copy-link-icon{
  height: 1rem;
}
.selected-icon{
  height: 1.25rem;
  padding-right: 0.5rem;
}

.styled-input{
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
}
.styled-input:focus{
	outline: none;
}

.hidden{
  display: none;
}