.settings-panel-button-container{
    
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
}

.settings-panel-button{
    height: 4rem;
    width: 4rem;
    border-radius: 2rem;
    background-color: #253e4c;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: .1rem 0.1rem 0.5rem rgb(0 0 0 / 50%);
}
.settings-panel-button:hover{
    background-color: #435e6c;
}
  
  .settings-icon{
    max-height: 2.5rem;
    padding: 0 1rem;
  }